<template>
    <NebulaTable
        class="request-directory-table"
    >
        <template v-slot:table>
            <table class="nebula-table">
                <thead class="nebula-table__head">
                    <tr class="nebula-table__row">
                        <!-- TODO: Make table sortable once we get real paging data -->
                        <NebulaTableHeader class="request-directory-table__header" v-for="(column, idx) in columns" :key="`column-${idx}`"
                            :text="column.header"
                        />
                        <NebulaTableHeader />
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="request in data"
                        :key="request.name"
                        class="nebula-table__row"
                    >
                        <RequestDirectoryTableCell
                            v-for="(column, idx) in columns"
                            :key="`cell-${idx}`"
                            :value="request[column.field]"
                            :data="column"
                            :detail="getDetails(column.field, request)"
                        />
                        <NebulaTableCell
                            class="request-directory-table__cell request-directory-table__cell--link"
                        >
                            <template v-slot:tableCell>
                                <NebulaIconButton
                                    icon="caret-right"
                                    :link="requestLink(request)"
                                    type="flat"
                                    size="s"
                                    routerLink
                                />
                            </template>
                        </NebulaTableCell>
                    </tr>
                </tbody>
            </table>
        </template>
    </NebulaTable>
</template>

<script>
import {
    // NebulaIcon,
    NebulaIconButton,
    NebulaTable,
    NebulaTableCell,
    NebulaTableHeader,
} from '@discoveryedu/nebula-components';
import formatDate from '@/mixins/formatDate';
import formatTime from '@/utils/formatTime';
import getOrdinalSuffix from '@/utils/getOrdinalSuffix';
import subjectIconMap from '@/data/subjectIconMap';
// import AvatarCell from '@/components/shared/tokens/AvatarCell.vue';
// import TableCell from '@/components/shared/tokens/TableCell.vue';
import RequestDirectoryTableCell from '@/components/shared/requests/RequestDirectoryTableCell.vue';

// import RequestStatus from '@/components/shared/tokens/RequestStatus.vue';

import {
    typeOptions,
    tagOptions,
} from '@/data/formData';
import {
    roles,
    requestDirectory as columns,
} from '@/constants';

export default {
    name: 'RequestDirectoryTable',
    components: {
        // NebulaIcon,
        NebulaIconButton,
        NebulaTable,
        NebulaTableCell,
        NebulaTableHeader,
        // AvatarCell,
        // TableCell,
        // RequestStatus,
        RequestDirectoryTableCell,
    },
    data() {
        return {
            typeOptions,
            subjectIconMap,
            roles,
            columns: [],
        };
    },
    mixins: [formatDate],
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        mode: String,
        loading: Boolean,
    },
    computed: {
        tagOptions,
        appMode() {
            return this.$store.state.app.mode;
        },
    },
    methods: {
        formatTime,
        getOrdinalSuffix,
        showConnector(request) {
            return this.appMode === roles.EDUCATOR_ROLE && request.employeeLastName;
        },
        // getTypeDisplay(type) {
        //     if (!type) {
        //         return '';
        //     }
        //     const typeObj = this.typeOptions.find((each) => each.value === type);
        //     return typeObj.text;
        // },
        getDetails(field, request) {
            const config = this.columns.find((each) => each.field === field);
            if (!config || !config.details) {
                return null;
            }

            const details = {};

            config.details.forEach((key) => {
                const keyToUse = key === 'organization' ? 'organizations' : key;
                details[key] = request[keyToUse];
            });

            return details;
        },
        getTagDisplay(subject) {
            const typeObj = this.tagOptions.find((each) => each.value === subject);
            return typeObj.text;
        },
        getSubjectIcon(subject) {
            return this.subjectIconMap[subject];
        },
        requestLink(request) {
            return `/request/${request.requestId}`;
        },
    },
    mounted() {
        this.columns = columns[this.appMode.toLowerCase()][this.mode];
    },
};
</script>

<style lang='stylus'>
.request-directory-table {
    &__cell{
        &--link {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__inline-cell {
        display: flex;
        justify-content: flex-end;
    }

    &__header {
        --nebula-table-header-text-color: $nebula-color-platform-interface-900;
    }
}

</style>

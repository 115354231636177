<template>
    <NebulaTableCell
        responsiveLabel="Request Type"
        class="reqdir-table-cell"
    >
        <template v-slot:tableCell>
            <span
                v-if="data.showRecommended && detail && detail.recommended"
                class="recommended-indicator"
            >
                Recommended
            </span>
            <span v-if="hasIcon">
                <SubjectIcon :subject="value" />
            </span>
            <RequestStatus
                v-if="isStatus"
                :status="value"
                :requestId="detail.requestId"
                :date="detail.startDateTime"
            />
            <RequestSchool v-else-if="isSchool" v-bind="detail" />
            <AvatarCell v-else-if="isAvatar" :data="avatarData" />
            <span v-else-if="isTime" class="reqdir-table-cell__time">
                {{ display }}
            </span>
            <span v-else>
                {{ display }}
            </span>
        </template>
    </NebulaTableCell>
</template>

<script>
import {
    NebulaTableCell,
} from '@discoveryedu/nebula-components';
import {
    typeOptions,
    tagOptions,
    gradeLevelOptions,
} from '@/data/formData';
import SubjectIcon from '@/components/shared/tokens/SubjectIcon.vue';
import RequestStatus from '@/components/shared/tokens/RequestStatus.vue';
import RequestSchool from '@/components/shared/tokens/RequestSchool.vue';
import AvatarCell from '@/components/shared/tokens/AvatarCell.vue';

export default {
    name: 'RequestDirectoryTableCell',
    components: {
        AvatarCell,
        NebulaTableCell,
        SubjectIcon,
        RequestStatus,
        RequestSchool,
    },
    props: {
        value: String,
        header: String,
        data: Object,
        detail: Object,
    },
    computed: {
        tagOptions,
        display() {
            const { field } = this.data;
            if (field === 'type') {
                if (!this.value) {
                    return '';
                }
                const typeObj = typeOptions.find((each) => each.value === this.value);
                return typeObj ? typeObj.text : '';
            }

            if (field === 'primarySubject') {
                const tagObj = this.tagOptions.find((each) => each.value === this.value);
                return tagObj ? tagObj.text : '';
            }

            if (field === 'gradeLevel' && this.value) {
                const gradeObj = gradeLevelOptions.find((each) => each.value === this.value);
                return gradeObj ? gradeObj.text : '';
            }

            return this.value;
        },
        hasIcon() {
            return this.data.field === 'primarySubject' && this.value;
        },
        isStatus() {
            return this.data.field === 'status';
        },
        isSchool() {
            return this.data.field === 'schoolName';
        },
        isTime() {
            return this.data.field === 'time';
        },
        isAvatar() {
            const avatarFields = ['educator', 'connector', 'organization'];
            return avatarFields.includes(this.data.field);
        },
        avatarData() {
            if (!this.isAvatar) {
                return null;
            }
            let { field } = this.data;
            let { detail } = this;

            if (field === 'connector') {
                field = 'employee';
            }

            // organization handling. This data is nested in an array
            if (field === 'organization' && (!detail.organization || detail.organization.length < 1)) {
                return null;
            }

            if (field === 'organization') {
                [detail] = detail.organization;
            }

            const avatarUrl = detail[`${field}AvatarUrl`];
            const name = detail[`${field}Name`];
            const deleted = detail[`${field}_deleted`];

            return {
                avatarUrl,
                name,
                deleted,
            };
        },
    },
};
</script>

<style lang="stylus">
.recommended-indicator {
    nebula-text-caption();
    nebula-text-semibold();

    color: $nebula-color-platform-neutral-700;
}

.reqdir-table-cell {
    &--recommended {
        display: flex;
        flex-direction: column;
        gap: $nebula-space-half;
    }
    &--no-wrap {
        min-width: max-content;
        white-space: nowrap;
    }
    &--link {
        display: flex;
        justify-content: flex-end;
    }
    &__time {
        white-space: nowrap;
    }
}
</style>

<template>
    <div v-if="hasData" class="avatar-cell">
        <ProfileAvatar
            :class="[
                'avatar-cell__avatar',
                { 'avatar-cell__avatar--deleted': data.deleted }
            ]"
            :image="data.avatarUrl"
            size="xs"
        />
        <div>
            <p v-if="data.name" class="avatar-cell__name">
                {{ data.name }}
                <span v-if="data.deleted" class="avatar-cell__name--deleted">
                    (Deactivated)
                </span>
            </p>
            <p v-if="data.title" class="avatar-cell__title">{{ data.title }}</p>
        </div>
    </div>
</template>

<script>
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';

export default {
    name: 'AvatarCell',
    components: {
        ProfileAvatar,
    },
    props: {
        label: String,
        data: Object,
    },
    computed: {
        hasData() {
            // avatar is not required and shows a default, but only show that default if there is name data
            return !!(this.data && this.data.name);
        },
    },
};
</script>

<style lang="stylus">
.avatar-cell {
    display: flex;
    align-items: center;

    &__avatar {
        margin-inline-end: $nebula-space-1x;

        &--deleted {
            opacity: 0.8;
        }
    }

    &__name, &__title {
        body-1();
    }

    &__name {
        // font-weight: 700;
        margin: 0;

        &--deleted {
            font-weight: 400;
            font-style: italic;
        }
    }

    &__title {
        margin: 0;
        line-height: 1em;
    }
}
</style>
